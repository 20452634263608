.main{
    display: flex;
    flex-direction: column;
    margin: 0;
    top:0;
    left:0;
    height: 100%;
    min-width: 770px;
    overflow: hidden;
    background-color: ghostwhite;
    //transform: scale(.5,.5);
    //-moz-transform: scale(.5,.5);
    //-webkit-transform: scale(.5,.5);
    //transform-origin: top left;
}

.menu-item{
    margin: 1rem;
}

.menu{
    display: flex;
    flex-direction: row;
    margin: 0;
    background-color: ghostwhite;
    position: relative;
    .date-picker{
        position: absolute;
        left: 78%;
        top: 1rem;
    }

    .picker-text{
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: left;
    }
}

.content{
    margin: 0;
    height: 100%;
    /*padding-left: 1rem;
    padding-right: 1rem;*/
}

.loading{
   margin: 0 auto;
    height: 300px;
    width: 400px;

}

.upload-screen{
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 0.5vw;
    width: 99% !important;
    overflow-y: auto;
}

.galery-div{
    width: 100%;
    margin-top: 0;
    min-height: 8rem;
    max-height: 8rem;
}
.right-div{
    width: 100%;
    margin-left: 0rem;
    margin-right: 2rem;
}

.buttons-div{
    width: 100%;
}

.button-basic{
    margin-left:0.5rem;
    margin-right:0.5rem;
}


.alert-wrapper{
    border-radius: 6px;
    display: block;
    overflow-y: hidden;
    /*padding: 15px 15px 15px 5px;*/
    position: absolute;
    width: 50%;
    margin-left: 47%;
}
.alertc{
    clear: both;
    list-style: none;
    max-height: 450px;
    overflow-y: auto;
    padding-left: 0;
    padding-top: 15px;
}

.loading-holder{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: gainsboro;
    margin: 0;
    padding: 0;
    opacity: 0.9;
    z-index: 1000;
    .loading{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-40%, -50%);
    }
}

.file-display{
    margin-left: 0.5vw;
    width: 99% !important;
    .map-td{
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        height: 0;
    }
    overflow-y: auto;
}

.map-holder{
    width: 100%;
}

.gauge-charts{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10%;
    width: 50%;

    position: absolute;


    .gauge-power{
        width: 120px;
    }
    .gauge-heart{
        width: 120px;
    }
    .gauge-cadence{
        width: 120px;
    }
    .gauge-speed{
        width: 120px;
    }
}

.animate-div{

    .map{

    }

    .map:-moz-full-screen {
        height: 100%;
    }
    .map:-webkit-full-screen {
        height: 100%;
    }
    .map:-ms-fullscreen {
        height: 100%;
    }
    .map:fullscreen {
        height: 100%;
    }
}

.track-map{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}
.slider-div{
    min-width: 200px;
    border: 0;
    margin-left: 1rem;
}
#chart_div{
    position: absolute;
    bottom: -40px;
    width: 100%;
}



.tools-div{
    min-width: 1000px;
    display: flex;
    flex-direction: row;
    height: 100%;
    /*background-color: #eff2f7;*/


    .tools-content {
        margin: 0 auto;
        display: flex;
        width: 90vw;
        height: 100%;
        max-width: 1600px;
        border: 1px;
        border-style: solid;
        border-top: 0;
        border-bottom: 0;
        border-color: #a6a7a8;
        background-color: white;


       /* max-height: 56vw;*/

        .tools-left {
            display: table-column;
            width: 75vw;
           /* max-width: 1200px;*/
            min-width: 800px;
            border-right-style: ridge;
            border-right-width: thin;
            border-right-color: gainsboro;
            overflow-y: auto;

            .google-chartline {
                height: 300px;
            }
            .tools-map {
                height: 300px;
            }
            .track-map {
                padding: 0.5rem;
                border-top-style: ridge;
                border-top-width: thin;
                border-top-color: gainsboro;
                height: auto;
                .map-holder{
                    width: 98%;
                    height: 100%;
                }
            }
        }
        .tools-right {
            box-sizing: border-box;
            min-width: 15rem;
            width: 30vw;
            padding-right: 1rem;
            .result {
                padding: 1rem;
                font-size: 1rem;
                display: table;
                width: 100%;
            }

            .resultBlock {
                display: flex;
                flex-direction: row;
                margin-top: 3vw;
            }
            .result-column {
                width: 45%;
            }
            .result-column-firs {

            }
            .result-row {

            }
        }
    }
}
.login{
    padding: 2rem;
    .login-content{
        margin: auto;
        width: 30%;
        min-width: 300px;
        border: 1px;
        border-radius: 5px;
        padding: 1rem;
        border-style: solid;
        border-color: gray;
    }
}

.laps-holder{
    margin-left: 1rem;
    width: 95%;
    height:30vw;
    overflow: auto;
    .laps-table{
        font-size: 0.8rem;
        width: 99%;

    }
}

.file-list{
    height: 100%;
    overflow-y: auto;
    background: rgb(227, 227, 227) none repeat scroll 0% 0%;
    box-sizing: content-box;
    display: flex;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
    position: relative;
    z-index: 0;
}

*,::before,::after {
    box-sizing: content-box !important;
}

.popup-main{
        width: 85%;
}

.calendar-links{
    display: flex;
    align-items: center;
}

.summary-title{
    font-size: 0.8rem;
    font-style: italic;
    font-weight: 700;
}

.summary-unit{
    font-size: 0.8rem;
    font-style: italic;
    font-weight: 400;
}
