.App{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 770px;
  margin: 0;
  padding: 0;
}

.display-data{
  display: flex;
  width: 100%;
  justify-content: center;
}

.result{
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: center;
}

.result-column{
  display: flex;

  flex-direction: column;

  margin-right: 0.3rem;
}

.result-column-firs{
  text-align: right;
  border-right-width: thin;
  border-right-color: darkgrey;
  border-right-style: solid;
  padding-right: 0.8rem;
  font-style: italic;
  margin-left: 3rem;
  font-weight: 600;
}

.result-row{
  height: 2rem;
}

.result-header{
  font-weight: bold;
  margin: 0.2rem;
  border-bottom-width: thin;
  border-bottom-color: darkgrey;
  border-bottom-style: solid;
}
